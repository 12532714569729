<template>
  <div id="container" v-if="fetch_data">
    <section id="group">
      <div class="wrap">
        <h2 class="sub_ttl"><span>テーマ別グループ参加状況</span></h2>
        <p class="lead_txt">
          アイコン、またはグループ名をクリックでそれぞれのグループを表示します。<br>
          参加していないグループへの投稿・コメントの書き込みは出来ません。グループに参加するためには、一覧から参加したいグループの「参加する」をクリックする必要があります。
        </p>
        <h3 class="bold">現在{{ joinCount() }}個のグループに参加しています</h3>
        <div class="group_list">
          <div class="group_set flex" v-for="space in fetch_data.spaces" :key="space.id">
            <div class="icon">
              <router-link :to="{ name: 'SnsSpace', params: {nav_category: 'social', space_id: space.id, room_id: 0}}">
                <img v-bind:src="space.image_file_url" alt="">
              </router-link>
            </div>
            <router-link :to="{ name: 'SnsSpace', params: {nav_category: 'social', space_id: space.id, room_id: 0}}"
                         class="info">
              <h3>{{ space.title }}</h3>
              <p v-html="space.description_text.substring(0, 80)"></p>
            </router-link>

            <template>
              <div class="btn_box" v-if="!space.user_join">
                <a href="javascript:void(0);" class="btn" @click="userJoin(space)">参加する</a>
              </div>
              <div class="btn_box" v-if="space.user_join">
                <a href="javascript:void(0);" class="btn join">参加中</a>
                <ul class="select_box" v-bind:class="{open: space.show}">
                  <li><a href="javascript:void(0);" @click="unUserJoin(space)">グループから退出する</a></li>
                  <li><a href="javascript:void(0);" class="close" @click="space.show = false">閉じる</a></li>
                </ul>
              </div>
            </template>
          </div>
        </div>
        <div class="btn_wrap">
          <a href="https://millioneyescoltd.my.webex.com/millioneyescoltd.my/j.php?MTID=m4fe8ca2cd74ef7704cebeba1d5199a7d" class="submit light chat" target="_blank"><i>複眼自由会議室</i></a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  components: {},
  data() {
    return {
      fetch_data: null
    }
  },
  watch: {
    $route(/*to, from*/) {
      this.reset()
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}sns/socials.json`, {})
          .then(response => {
            response.data
            response.data.spaces.forEach((space) => {
              space.show = false
            })

            this.fetch_data = response.data
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
            // user js への onload処理
            window.$(".group_set .join").on("click", function() {
              window.$(this).closest(".btn_box").find(".select_box").slideToggle();
              window.$(this).closest(".btn_box").find(".select_box").toggleClass("open");
            });
            window.$(".group_set .select_box a").on("click", function() {
              window.$(this).closest(".btn_box").find(".select_box").fadeOut();
              window.$(this).closest(".btn_box").find(".select_box").removeClass("open");
            });

            window.$(".room_join_box.join .submit").on("click", function() {
              window.$(this).closest(".btn_wrap").find(".select_box").slideToggle();
              window.$(this).closest(".btn_wrap").find(".select_box").toggleClass("open");
            });
            window.$(".room_join_box.join .select_box a").on("click", function() {
              window.$(this).closest(".btn_wrap").find(".select_box").fadeOut();
              window.$(this).closest(".btn_wrap").find(".select_box").removeClass("open");
            });
            window.$(document).on('click',function(e) {
              if(!window.$(e.target).closest('.group_set .join,.room_join_box.join .submit').length) {
                window.$(".select_box").fadeOut();
                window.$(".select_box").removeClass("open");
              }
            });
          })
    },
    /*
    showMenu(space) {
      const show = space.show
      this.fetch_data.spaces.forEach((space) => {
        space.show = false
      })
      space.show = !show
    },
     */
    userJoin(space) {
      this.axios
          .post(`${this.env.api_base_url}sns/social_space_user_join.json`, {
            id: space.id
          })
          .then(() => {
            space.show = false
            space.user_join = true
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },
    unUserJoin(space) {
      this.axios
          .post(`${this.env.api_base_url}sns/social_space_un_user_join.json`, {
            id: space.id
          })
          .then(() => {
            space.show = false
            space.user_join = false
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },
    joinCount() {
      let count = 0
      this.fetch_data.spaces.forEach((space) => {
        if (space.user_join) count++
      })
      return count
    }
  }
}
</script>